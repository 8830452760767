.app-container {
  margin: 50px auto;
  max-width: 1000px;
  padding: 30px;
  text-align: center;
  margin-top: 10px;
}

.cta-button {
  width: 400px;
  padding: 15px 25px;
  color: white;
  border: none;
  font-size: 20px;
  margin: 30px;
  cursor: pointer;
  box-shadow: 2px 5px #222;
}