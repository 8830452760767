header {
    padding: 40px 60px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 0px;
    color: white;
}

header div {
    margin: 0px 25px;
    background: #232323;
    padding: 15px 25px;
    font-size: 14px;
}

.online-status {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 10px;
    display: inline-block;
}

.online {
    background: #9aea7c;
}

.offline {
    background: red;
}